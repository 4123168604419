html,
body {
    font-family:
        archia,
        -apple-system,
        BlinkMacSystemFont,
        "Segoe UI",
        Roboto,
        Helvetica,
        Arial,
        sans-serif,
        "Apple Color Emoji",
        "Segoe UI Emoji",
        "Segoe UI Symbol";
}

.wrapper {
    width: 85%;
    max-width: 1180px;
    margin: 0 auto;
}

a.grow {
    transition: all 0.2s ease-in-out;
}

a.grow:hover {
    transform: scale(1.05);
}

.lpdr-hero {
    min-height: 700px;
    background: url("../../images/3-singes.png") no-repeat center right;
    background-size: 45%;
}

.lpdr-blacksection {
    background: #000;
    z-index: 100;
}

.lpdr-whitesection {
    background: #fff;
    z-index: 100;
}

.lpdr-smallbtn,
.lpdr-smallbtn-secondary {
    display: block;
    width: 190px;
    font-weight: 700;
    font-size: 0.9em;
    color: #000;
    text-align: center;
    border: 1px solid #000;
    padding: 6px 12px;
    text-decoration: none;
}

.lpdr-smallbtn-secondary {
    border-color: transparent;
}

.lpdr-tag {
    background: #ff4c5d;
    border-radius: 1px;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    text-align: center;
    padding: 3px 8px;
}

.lpdr-bigtitle,
.lpdr-bigtitle-reverse {
    font-weight: 700;
    font-size: 3.5em;
    line-height: 1.2em;
    z-index: 100;
}

.lpdr-bigtitle {
    color: #000;
}

.lpdr-bigtitle-reverse {
    color: #fff;
}

.lpdr-subtitle,
.lpdr-subtitle-hero,
.lpdr-subtitle-reverse {
    font-weight: 300;
    font-size: 1.33em;
    line-height: 1.8em;
    z-index: 100;
}

.lpdr-subtitle,
.lpdr-subtitle-reverse {
    max-width: 590px;
}

.lpdr-subtitle {
    font-weight: 500;
}

.lpdr-subtitle-reverse {
    font-weight: 400;
    color: #bbb;
}

.lpdr-bigcta,
.lpdr-bigcta-inverted {
    display: block;
    max-width: 320px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    padding: 20px 35px;
    text-decoration: none;
}

.lpdr-bigcta {
    color: #fff;
    background: #000;
}

.lpdr-bigcta-inverted {
    color: #000;
    background: #fff;
    border: 1px solid #000;
}

.lpdr-bigcta:hover,
.lpdr-bigcta:focus {
    background: #111;
    color: #fff;
}

.lpdr-bigcta-inverted:hover,
.lpdr-bigcta-inverted:focus {
    background: #fff;
    color: #111;
}

.lpdr-cta,
.lpdr-cta-inverted {
    display: block;
    max-width: 400px;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    padding: 20px 35px;
    text-decoration: none;
    margin: 0 auto;
}

.lpdr-cta {
    background: #fff;
    color: #000;
}

.lpdr-cta-inverted {
    background: #000;
    color: #fff;
}

.lpdr-monkeys {
    top: 0;
    max-width: 600px;
    z-index: 1;
}

.lpdr-bluemonkey {
    transform: translate(60px, 180px);
}

.lpdr-yellowmonkey {
    transform: translate(-120px, 80px);
}

.lpdr-videoshots {
    width: 800px;
    margin: 0 auto;
}

.testimonials {
    margin: 60px auto 150px;
}

.testi {
    padding: 25px 20px;
    background: #fff;
    box-shadow: 0 13px 15px 0 rgb(176 176 176 / 50%);
    border-radius: 3px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.testi-avatar {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    background: #d8d8d8;
    border: 1px solid #979797;
}

.testi-quote {
    font-size: 15px;
    color: #000;
    line-height: 23px;
    margin-bottom: 15px;
}

.testi-name {
    font-size: 14px;
    color: #000;
    font-weight: 700;
}

.testi-job {
    font-size: 13px;
    color: #000;
}

.testi-avatar-container {
    min-width: 80px;
}

.photo-border {
    border-radius: 4px;
    margin-bottom: 80px;
}

.img-container {
    padding: 0 50px;
}

.margin-top-xl {
    margin-top: 60px;
}

.margin-top-l {
    margin-top: 40px;
}

.lpdr-connexion-2 {
    display: none;
}

footer {
    color: #2e3138;
}

@media (max-width: 1280px) {
    .testi {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .wrapper {
        width: 100%;
    }

    .lpdr-connexion {
        display: none;
    }

    .lpdr-hero {
        background-size: 80%;
    }

    .lpdr-connexion-2 {
        display: block;
    }

    header {
        padding: 10px;
    }

    .img-container {
        padding: 0 10px;
    }

    .no-margin-mobile {
        margin: 0;
    }

    .lpdr-hero,
    .lpdr-blacksection,
    .lpdr-whitesection {
        padding-right: 1%;
        padding-left: 1%;
    }

    .lpdr-herotxt {
        z-index: 100;
        padding: 10% 1% 20%;
    }

    .lpdr-monkeys {
        /* position: relative; */
        width: 500px;
        z-index: 1;
    }

    .lpdr-bigtitle,
    .lpdr-bigtitle-reverse {
        font-size: 2.6em;
    }
}
